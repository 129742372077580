@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;300;700&display=swap');

::-webkit-scrollbar {
  width: 1px;
  height: 1px;
}

::-webkit-scrollbar-thumb {
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.15) 0%, rgba(0, 0, 0, 0.15) 100%), radial-gradient(at top center, rgba(255, 255, 255, 0.40) 0%, rgba(0, 0, 0, 0.40) 120%) #989898;
  background-blend-mode: multiply, multiply;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.15) 0%, rgba(0, 0, 0, 0.15) 100%), radial-gradient(at top center, rgba(255, 255, 255, 0.40) 0%, rgba(0, 0, 0, 0.40) 120%) #989898;
  background-blend-mode: multiply, multiply;
}

::-webkit-scrollbar-track {
  background: #2b2b2b;
}

body {
  background-color: #000000;
  width: 100%;
  position: relative;
}

.App {
  text-align: center;
  font-family: 'Inter';
  // max-width: 2304px;
  //margin: 0 auto;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.page-padding {
  position: relative;
  box-sizing: border-box;
  padding: 0 100px;
  // padding-left: 50px;
  width: 100%;

  @media (max-width: 1280px) {
    padding: 0 60px;
  }

  @media (max-width: 1024px) {
    padding: 0 40px;
  }

  @media (max-width: 768px) {
    padding: 0 30px;
  }

  @media (max-width: 480px) {
    padding: 0 20px;
  }
}

.container-large {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  // max-width: 1370px;
  max-width: 1180px;
}


// .App-header {
//   background-color: #282c34;
//   min-height: 100vh;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   font-size: calc(10px + 2vmin);
//   color: white;
// }

// .App-link {
//   color: #61dafb;
// }

// @keyframes App-logo-spin {
//   from {
//     transform: rotate(0deg);
//   }

//   to {
//     transform: rotate(360deg);
//   }
// }


.logo {
  width: 180px;
  //padding: 43px 0px 20px 0px;
  /* margin-left: 115px; */


  @media (max-width: 1280px) {
    width: 150px;
  }

  @media (max-width: 480px) {
    width: 120px;
  }
}

// .connect-btn-wrapper {
//   // padding: 2px;
//   // border-radius: 30px;
//   // background-color: linear-gradient(180deg, #1B40FF 0%, #8B9EFF 100%);
// }

#header {
  margin-top: 40px;

}

#connectBtn {
  font-weight: 700;
  font-size: 20px;
  padding: 0.75rem 2rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFFFFF;
  // font-family: 'Montserrat', sans-serif;
  box-sizing: border-box;
  border-radius: 20px;
  background: linear-gradient(#000000, #000000) padding-box,
    linear-gradient(180deg, #1B40FF 0%, #8B9EFF 100%) border-box;
  border: 1px solid transparent;

  @media (max-width: 768px) {}

  @media (max-width: 480px) {
    padding: 0.5rem 1.5rem;
    font-size: 16px;
  }
}

// .paybtn {
//   box-sizing: border-box;
//   border-radius: 5px;
//   background: #1B40FF;
//   padding: 20px;
// }

#banner {
  // padding: 0 0px 0 150px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: flex-start;
    text-align: left;
  }
}

#buysection {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media (max-width: 768px) {
    align-items: flex-start;
  }
}

#headerimage {
  position: relative;
}

#headerimage .boeg-image {
  max-width: 438px;

  @media (max-width: 1280px) {
    max-width: 360px;
  }

  @media (max-width: 1024px) {
    max-width: 300px;
  }

  @media (max-width: 768px) {
    margin-top: 30px;
    margin-bottom: 30px;
    max-width: 50%;
  }
}

// #headerimage .checkmark-image {
//   position: absolute;
//   top: 225px;
//   left: 20px;
//   max-width: 128px;
// }

.title-wrapper {
  margin-top: 100px;

  @media (max-width: 768px) {
    margin-top: 0;
  }
}

.maintitle {
  font-weight: 700;
  font-size: 100px;
  line-height: 1.2;
  text-align: end;

  @media (max-width: 1280px) {
    font-size: 80px;
  }

  @media (max-width: 768px) {
    text-align: left;
    font-size: 60px;
  }

  @media (max-width: 480px) {
    font-size: 40px;
  }

}

.maintitle.subpage {
  font-weight: 700;
  font-size: 80px;
  line-height: 1.2;
  text-align: end;

  @media (max-width: 1280px) {
    font-size: 70px;
  }

  @media (max-width: 768px) {
    text-align: left;
    font-size: 60px;
  }

  @media (max-width: 480px) {
    font-size: 40px;
  }

}

.lead {
  background: linear-gradient(180deg, #1B40FF 0%, #8B9EFF 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  font-size: 40px;
  line-height: 1.2;
  margin-top: 30px;
  max-width: 550px;
  text-align: end;

  @media (max-width: 1280px) {
    font-size: 36px;
  }

  @media (max-width: 768px) {
    text-align: left;
  }

  @media (max-width: 480px) {
    font-size: 24px;
  }
}

.impressum-txt {
  font-size: 40px;
  line-height: 1.5;
  max-width: 550px;
  text-align: end;

  @media (max-width: 1280px) {
    font-size: 36px;
  }

  @media (max-width: 768px) {
    text-align: left;
  }

  @media (max-width: 480px) {
    font-size: 24px;
  }

  &.first {
    margin-top: 30px;
  }
}

.button-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media (max-width: 768px) {
    align-items: flex-start;
  }
}

#openModalBtn {
  margin-top: 60px;
  background: #1B40FF;
  border-radius: 45px;
  padding: 0.75rem 2rem;
  font-weight: 700;
  display: flex;
  justify-content: center;
  font-size: 20px;
  // line-height: 56px;
  letter-spacing: 0.01em;
  // font-family: 'Montserrat', sans-serif;
  // width: 424px;
  // height: 98px;
  align-items: center;
}

#walletlink {
  font-size: 20px;

  @media (max-width: 480px) {
    font-size: 16px;
  }
}


// #buyblock {
//   margin-top: 136px;
//   float: right;
// }

// #walletlink {
//   position: relative;
//   font-size: 30px;
//   margin-top: 16px;
//   line-height: 36px;
// }

// #walletlink::before {
//   position: absolute;
//   content: '>';
//   display: block;
//   left: -28px;
//   top: 0px;
// }

#saleEnds {
  display: flex;
  justify-content: space-between;


  @media (max-width: 1024px) {
    margin-top: 100px;
  }

  @media (max-width: 968px) {
    // align-items: flex-end;
    // justify-content: flex-end;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  // @media (max-width: 768px) {
  //   align-items: flex-start;
  //   justify-content: flex-start;
  // }

}

.sale-timer {
  // align-self: flex-start;
  margin-top: 15px;

  // @media (max-width: 968px) {
  //   align-self: flex-end;
  // }
}

.sale-ends-title {
  font-size: 30px;
  line-height: 1;
  font-weight: 700;
  text-align: left;

  @media (max-width: 480px) {
    font-size: 24px;
  }
}

.timer-list {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 50px;
  margin-top: 20px;

  @media (max-width: 480px) {
    gap: 25px;
  }
}

.time {
  font-size: 70px;
  line-height: 1;
  font-weight: 700;
  text-align: left;

  @media (max-width: 1024px) {
    margin-top: 20px;
    font-size: 50px;
  }

  @media (max-width: 480px) {
    font-size: 32px;
  }
}

.period {
  font-size: 20px;
  letter-spacing: 0.01em;
  text-align: left;

  @media (max-width: 480px) {
    font-size: 16px;
  }
}

.sale-dropdown-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  // margin-right: 84px;
  margin-top: 55px;
  gap: 30px;

  @media (max-width: 768px) {
    width: 100%;
    justify-content: space-between;
  }

  @media (max-width: 480px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

.dropdown-header {
  position: relative;
  width: 100%;
}

.dropdown-title {
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 70px;
  padding: 1rem 3rem;
  letter-spacing: 0.01em;
  border-radius: 45px;
  border: 1px solid #919191;
  // width: 300px;
  // height: 98px;
  cursor: pointer;
  position: relative;

  @media (max-width: 480px) {
    font-size: 16px;
    column-gap: 20px;
    padding: 1rem 2rem;
    width: 100%;
  }

}

.dropdown-title .angle-icon {
  height: 16px;
  transform: rotate(0deg);
  transition: all 0.25s ease-out;

  &.open {
    transform: rotate(180deg);
    transition: all 0.25s ease-out;
  }

  @media (max-width: 480px) {
    height: 12px;
  }
}

.opensea {
  width: 60px;

  @media (max-width: 480px) {
    width: 50px;
  }
}

.sale-details {
  @media (max-width: 480px) {
    width: 100%;
  }
}

.sale-details-list {
  position: absolute;
  border-radius: 45px;
  top: 100px;
  right: 0;
  background: rgba(#fff, .10);
  box-shadow: 0 4px 27px rgba(0, 0, 0, .6);
  -webkit-backdrop-filter: blur(12px);
  backdrop-filter: blur(12px);
  width: 500px;
  padding: 3rem;

  @media (max-width: 480px) {
    width: 100%;
    padding: 2rem;
  }
}

.details-list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid #919191;
  text-transform: uppercase;
  color: #fff;
  // padding: 10px;

  &:nth-child(1) {
    padding-top: 0px;
  }

  @media (max-width: 480px) {
    width: 100%;
    text-align: left;
    font-size: 14px;
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

.outer-link {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;

  @media (max-width: 480px) {
    gap: 5px;
  }
}


#why {
  margin-top: 200px;
}

.h2title {
  font-weight: 700;
  line-height: 1.2;
  font-size: 60px;
  margin-bottom: 50px;

  span {
    display: block;
  }

  @media (max-width: 1024px) {
    font-size: 50px;
  }

  @media (max-width: 968px) {
    font-size: 40px;
  }

  @media (max-width: 480px) {
    font-size: 30px;
    text-align: left;
  }
}

.h2title.success {
  margin-bottom: 20px;
}

p.success {
  margin-bottom: 50px;

  @media (max-width: 480px) {
    text-align: left;
  }
}

.why-wrapper {
  display: flex;
  column-gap: 70px;

  @media (max-width: 968px) {
    flex-direction: column;
    row-gap: 60px;
  }

  @media (max-width: 480px) {
    justify-content: flex-start;
    text-align: left;
  }
}

.why-item {
  flex: 1 1 0;
  width: 0;

  @media (max-width: 968px) {
    width: 100%;
  }
}

.icon {
  margin: 0 auto;
  height: 80px;

  @media (max-width: 480px) {
    margin: 0;
  }
}



.h3title {
  font-weight: 700;
  font-size: 30px;
  line-height: 1.2;
  margin: 30px 0;

  span {
    display: block;
  }

  @media (max-width: 480px) {
    font-size: 24px;
  }
}

#newsletter {
  margin-top: 200px;
}

.contact-form {
  display: grid;
  max-width: 600px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-template-areas:
    "firstname lastname"
    "email email"
    "dropdown dropdown"
    "submit submit"
    "message message";
  max-width: 800px;
  margin: 0 auto;
  // margin-top: 60px;
  grid-gap: 30px 30px;

  @media (max-width: 480px) {
    display: flex;
    flex-direction: column;
  }
}

.contact-form input {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  width: 100%;
  border-radius: 45px;
  border: 1px solid #919191;
  background-color: #000000;
  text-align: center;
  outline: none;
  font-size: 20px;
  text-align: center;
  -webkit-appearance: none;
  appearance: none;

  @media (max-width: 480px) {
    font-size: 16px;
    text-align: left;
    align-items: flex-start;
    justify-content: flex-start;
    padding-left: 30px;
  }
}

input:focus {
  // -webkit-appearance: none;
  // appearance: none;
  outline: none;
}

.contact-form input[type="radio"] {
  width: auto;
}

.contact-form input::placeholder {
  color: #AFAFAF;
  font-weight: 300;
  text-align: center;

  @media (max-width: 480px) {
    text-align: left;
    align-items: flex-start;
    justify-content: flex-start;
  }
}

select#country {
  // background: transparent;
  // width: 268px;
  // padding: 5px;
  // font-size: 16px;
  // line-height: 1;
  // border: 0;
  // border-radius: 0;
  // height: 34px;

  -webkit-appearance: none;
  appearance: none;
  grid-area: dropdown;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  width: 100%;
  border-radius: 45px;
  border: 1px solid #919191;
  background-color: #000000;
  text-align: center;
  outline: none;
  font-size: 20px;
  text-align: center;
  text-align-last: center;

  &::after {
    content: 'Hallo';
  }

  @media (max-width: 480px) {
    font-size: 16px;
    text-align: left;
    padding-left: 30px;
  }
}

.option {
  -webkit-appearance: none;
  appearance: none;
  text-align: center;
  text-align-last: center;
}


.contact-form button {
  max-width: 50%;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 14px;
  border-radius: 45px;
  border: 1px solid #919191;
  background-color: #000000;
  color: #AFAFAF;
  margin: 0 auto;
  font-size: 20px;

  @media (max-width: 480px) {
    font-size: 16px;
  }
}


#first_name {
  grid-area: firstname;
}

#last_name {
  grid-area: lastname;
}

#submit {
  grid-area: submit;
}



.form-dropdown-wrapper {
  grid-area: dropdown;
  position: relative;
}

.form-dropdown-wrapper .sale-details-list {
  max-width: 528px;
  left: 30%;
}

.form-dropdown-wrapper .details-list-item {
  border-bottom: none;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 15px;
  margin-top: 0;
  margin-bottom: 20px;
}

.form-dropdown-wrapper .details-list-item input {
  margin-top: 5px;
}

.form-dropdown-wrapper .details-list-item.checked {
  margin-bottom: 0;
}

.form-dropdown-wrapper .details-list-item.checked label {
  padding-bottom: 30px;
  border-bottom: 5px solid #FFFFFF;
}

.form-dropdown-wrapper .dropdown-title {
  width: 100%;
  justify-content: center;
  color: #AFAFAF;
  position: relative;
}

.form-dropdown-wrapper .angle-icon {
  position: absolute;
  right: 50px;
  top: 50%;
  transform: translateY(-50%);
  height: 16px;
  // top: 35px;

  @media (max-width: 480px) {
    height: 12px;
  }
}

#email {
  grid-area: email;
}

.conact-form button {
  grid-area: submit;
}

.contact-form h2 {
  grid-area: message;
}



#faq {
  margin-top: 200px;
}

.faq-list {
  max-width: 800px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  gap: 30px;
}

.faq-item a {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 60px;
  padding: 20px 40px;
  width: 100%;
  border: 1px solid #919191;
  border-radius: 45px;

  @media (max-width: 480px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
    padding: 2rem;
    gap: 1.5rem;
  }
}

.faq-item img {
  height: 60px;
  width: 60px;
}

.faq-text {
  font-size: 20px;
  //line-height: 20px;
  font-weight: 700;
  text-align: left
}

#footer {
  margin-top: 200px;
  margin-bottom: 50px;
  // width: 100%;
  // margin: 200px auto 50px auto;
  // display: flex;
}

.faq-answer {
  p {
    margin-top: 30px;
    margin-bottom: 30px;

    &:nth-child(1) {
      margin-bottom: 1.5rem
    }

    @media (max-width: 480px) {
      text-align: left;
    }
  }

  .hide-answer {
    display: none;

  }

  // &:nth-child(1) {
  //   margin-top: 30px;
  // }

  // &:nth-child(2) {
  //   margin-top: 1.5rem;
  // }
}

.footer-wrapper {
  max-width: 800px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;

  @media (max-width: 480px) {
    flex-direction: column;
  }
}

#footer p,
#footer a {
  font-size: 20px;
  // line-height: 36px;
}

.overflow {
  overflow: hidden;
}


/* The Modal (background) */
.modal {
  /* display: none; Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 1;
  /* Sit on top */

  width: 100%;
  height: 100%;
  // height: 100%;
  /* Full width */
  //height: 100%;
  /* Full height */
  overflow-y: auto;
  /* Enable scroll if needed */
  // background-color: rgb(0, 0, 0);

  // background: rgba(#000, .65);
  // box-shadow: 0 4px 27px rgba(0, 0, 0, .6);
  // -webkit-backdrop-filter: blur(12px);
  // backdrop-filter: blur(12px);
  // /* Fallback color */
  // border-radius: 30px;
  // max-width: 588px;
  // border: 1px solid #919191;
  // padding: 40px;

  //left: 50%;
  //transform: translateX(-50%);
  top: 0px;
  left: 0px;
}

.modal-content {
  background: rgba(#000, .65);
  box-shadow: 0 4px 27px rgba(0, 0, 0, .6);
  -webkit-backdrop-filter: blur(12px);
  backdrop-filter: blur(12px);
  /* Fallback color */
  border-radius: 30px;
  max-width: 588px;
  border: 1px solid #919191;
  padding: 40px;
  margin: 50px auto;

  @media (max-width: 480px) {
    max-width: 90%;
    margin: 30px auto;
    padding: 20px;
  }
}

.modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  //margin-bottom: 40px;
}


.price-wrap {
  display: flex;
  justify-content: space-between;

}

.modaltitle {
  font-size: 40px;
  line-height: 1.2;

  @media (max-width: 480px) {
    font-size: 30px;
  }
}

.modaltitle,
.minortitle {
  font-weight: 700;

  span {
    text-align: left;
    display: block;
    font-weight: 400;
    line-height: 1.5;

    &.bold {
      font-weight: 700;
    }
  }
}

/* The Close Button */
.close {
  font-size: 36px;
  color: #aaa;
  float: right;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #FFFFFF;
  text-decoration: none;
  cursor: pointer;
}

.modal-flex {
  margin-top: 30px;
  display: flex;
  align-items: flex-end;
  column-gap: 30px;

  @media (max-width: 480px) {
    // flex-direction: column;
    column-gap: 20px;
  }
}

.boeg-img {
  border: 1px solid #888;
  border-radius: 30px;
  max-width: 100px;

  @media (max-width: 480px) {
    max-width: 80px;
  }
}

/* Modal Content/Box */
#modal-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.nft-title {
  font-size: 30px;
  line-height: 1.5;
  font-weight: 700;

  @media (max-width: 480px) {
    font-size: 20px;
  }
}


.minortitle {
  font-size: 20px;
  line-height: 1.2;
  font-weight: 700;

  @media (max-width: 480px) {
    font-size: 20px;
  }
}

.subtitle {
  font-size: 20px;
  line-height: 1.2;
  font-weight: 400;

  @media (max-width: 480px) {
    font-size: 20px;
  }
}

.mint-box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;

  @media (max-width: 480px) {
    margin-top: 20px;
  }
}

.mint-box-title {
  font-size: 20px;
  line-height: 1.2rem;
  font-weight: 700;

  @media (max-width: 480px) {
    font-size: 20px;
  }
}


.quantity-box {
  display: flex;
  column-gap: 1rem;

  span,
  button {
    font-size: 20px;

    @media (max-width: 480px) {
      font-size: 16px;
    }
  }
}


.payment-method-title {
  @media (max-width: 480px) {
    font-size: 20px;
  }
}

.text-left label {
  @media (max-width: 480px) {
    font-size: 16px;
  }
}

.h-full button {
  @media (max-width: 480px) {
    font-size: 20px;
  }
}

#mintAmount {
  border: 1px solid #fff;
  border-radius: 1rem;
  padding: 0 1rem;
}


.hidden {
  display: none;
}

#mainradio,
#mainradio label {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 15px;
}

// #mainradio:first-child label {
//   gap: 0;
// }

#mainradio img {
  width: 30px;
  height: 30px;
}

#news input {
  border-color: #1B40FF;
  border: 2px solid;
  border-radius: 20px;
  padding: 5px;
  padding-left: 20px;
  padding-right: 20px;
  background: gba(255, 255, 255, 0.3);
  color: #AFAFAF;
}

#news button {
  margin-left: 20px;
}

#invoiceex {
  margin: 30px 0;
}

.invoicetext {
  margin-top: 50px;

  p {
    text-align: end;

    span {
      font-weight: 700;
    }

    @media (max-width: 768px) {
      text-align: start;
    }
  }
}

.banner {
  padding: 40px 40px;
  border: 1px solid #919191;
  border-radius: 45px;
}




// .payment-method {
//   display: flex;
//   row-gap: 60px;
// }





// .cardtext {
//   text-align: justify;
// }





// #buysection {
//   margin-top: 34px;
//   margin-right: 86px;
//   width: 100%;
// }

// #buysection .maintitle {
//   line-height: 192px;
// }

// #why .h2title,
// #what .h2title {
//   font-size: 80px;
//   line-height: 97px;
//   max-width: 820px;
//   margin: 100px auto 93px;
// }

// #why .cardtext,
// #what .cardtext {
//   font-size: 36px;
//   line-height: 44px;
//   text-align: justify;
//   margin-top: 14px;
// }

// .col-item {
//   max-width: 536px;
//   margin: 0 auto;
//   display: grid;
//   grid-template-rows: auto auto 1fr;
//   grid-template-columns: 1fr;
// }

// .col-item .icon {
//   min-width: 164px;
//   height: 164px;
// }

// .col-item .h3title {
//   font-weight: 700;
//   font-size: 46px;
//   line-height: 56px;
//   text-align: center;
//   margin-top: 61px;
// }

// #what .col-item .h3title {
//   font-size: 44px;
//   max-width: 83%;
//   margin: 61px auto 0;
// }









// @media (max-width: 1560px) {
//   #buysection .maintitle {
//     font-size: 100px;
//     line-height: 120px;
//   }

//   #banner {
//     padding: 0;
//   }

//   .col-item .h3title {
//     font-size: 36px;
//     line-height: 40px;
//   }

//   #what .col-item .h3title {
//     font-size: 36px;
//   }

//   #why .cardtext,
//   #what .cardtext {
//     font-size: 24px;
//     line-height: 30px;
//   }

//   #newsletter .h31title {
//     font-size: 60px;
//     line-height: 70px;
//   }

//   .dropdown-title {
//     height: 100%;
//     font-size: 24px;
//   }

//   .form-dropdown-wrapper .angle-icon {
//     top: 12px;
//   }

//   // .logo {
//   //   width: 300px;
//   //   padding: 0;
//   //   margin: 0;
//   // }

//   #connectBtn {
//     // margin: 0;
//     // width: 215px;
//     // height: 50px;
//     // font-size: 20px;
//     // margin-bottom: 10px;
//   }

//   #headerimage .boeg-image {
//     max-width: 395px;
//   }

//   // #header {
//   //   padding: 33px 116px 20px 90px;
//   // }

//   #header .logo {
//     margin-bottom: 3px;
//   }

//   #buysection {
//     margin-right: 116px;
//     margin-top: 30px;
//   }

//   #headerimage {
//     margin-left: 120px;
//   }

//   #headerimage .checkmark-image {
//     position: absolute;
//     top: 200px;
//     left: 35px;
//     max-width: 135px;
//   }

//   #buysection p {
//     font-size: 36.4px;
//     margin-top: 25px;
//     font-weight: 500;
//     max-width: 540px;
//   }

//   #openModalBtn {
//     width: 260px;
//     height: 60px;
//     font-size: 28px;
//   }

//   #buyblock {
//     margin-top: 60px;
//   }

//   #walletlink {
//     font-size: 19px;
//     margin-top: 8px;
//   }

//   #walletlink::before {
//     left: -20px;
//   }

//   .sale-ends-title {
//     font-size: 20px;
//     margin-left: 174px;
//   }

//   .time {
//     font-size: 45px;
//     line-height: 50px;
//   }

//   .period {
//     font-size: 20px;
//     line-height: 1;
//   }

//   .timer-list {
//     gap: 117px;
//     margin-left: 174px;
//   }

//   .opensea {
//     width: 50px;
//   }

//   .dropdown-title {
//     width: 348px;
//     font-size: 22px;
//     padding: 4px 24px;
//     font-weight: 300;
//   }

//   .sale-dropdown-wrapper {
//     gap: 10px;
//     margin-right: 116px;
//   }

//   .dropdown-title .angle-icon {
//     right: 18px;
//     top: 15px;
//     width: 15px;
//   }

//   #why .h2title,
//   #what .h2title {
//     margin: 60px auto 90px;
//     font-size: 70px;
//   }

//   .col-item .icon {
//     min-width: 130px;
//     height: 130px;
//   }

//   .col-item .h3title {
//     margin-top: 60px;
//     font-size: 35px;
//   }

//   #why .col-item .h3title {
//     max-width: 95%;
//     margin: 60px auto 18px;
//   }

//   #why .cardtext,
//   #what .cardtext {
//     font-size: 25px;
//   }

//   .pr-\[70px\] {
//     padding-right: 116px;
//   }

//   .pl-\[125px\] {
//     padding-left: 94px;
//   }

//   .px-\[100px\] {
//     padding-left: 94px;
//     padding-right: 116px;
//   }

//   #what .col-item .icon {
//     min-width: 120px;
//     height: 120px;
//   }

//   #newsletter {
//     margin-top: 100px;
//   }

//   .conact-form {
//     margin-top: 59px;
//     max-width: 800px;
//   }

//   .conact-form input {
//     height: 50px;
//   }

//   .conact-form input:nth-child(3) {
//     max-width: 100%;
//   }

//   .conact-form button {
//     width: 377.5px;
//     height: 50px;
//   }

//   #faq {
//     margin-top: 100px;
//   }

//   #faq .h2title {
//     font-size: 60px;
//     line-height: 70px;
//     margin-bottom: 26px;
//   }

//   .faq-item img {
//     max-width: 45px;
//     height: 45px;
//   }

//   .faq-list {
//     max-width: 1017px;
//     gap: 20px;
//   }

//   .faq-item a {
//     padding: 27px 58px;
//     border-radius: 35px;
//   }

//   .faq-text {
//     line-height: 1;
//     font-size: 23px;
//   }

//   #footer {
//     margin-top: 151px;
//     padding-top: 0;
//     padding-bottom: 32px;
//   }

//   #footer p {
//     font-size: 25px;
//     text-align: center;
//   }

//   .modaltitle {
//     font-size: 28px;
//     line-height: 1;
//   }

//   .modal {
//     max-width: 500px;
//     padding-top: 27px;
//   }

//   .mb-7 {
//     margin-bottom: 20px;
//   }

//   #modal-content {
//     margin-top: 40px;
//   }

//   .minortitle {
//     font-size: 20px;
//     line-height: 24px;
//   }

//   .mint-box-title {
//     font-size: 20px;
//     line-height: 24px;
//   }
// }